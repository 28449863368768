<template>
  <div class="container">
    <!-- 头部大背景 -->
    <div class="header">
      <canvasBack />
      <div class="mask-layer">
        <div class="title">CatchLightPlugin</div>
        <div>{{$t('plugIn.bg')}}</div>
        <div style="margin-top: 20px">
          <el-button class="experience-btn1" @click="experience">{{$t('plugIn.bg-btn1')}}</el-button>
          <el-button class="experience-btn2" style="color: #fff" @click="gotoplug">{{$t('plugIn.bg-btn2')}}</el-button>
        </div>
      </div>
    </div>
    <!-- 头部大背景end -->
    <!-- 案列演示 -->
    <!-- <div class="case-cantainer">
      <img class="bgimg1" src="https://holodata.prometh.xyz/webMetaTrade/person.png"></img>
      <img class="bgimg2" src="https://holodata.prometh.xyz/webMetaTrade/sack.png"></img>
      <h3>{{$t('plugIn.case')}}</h3>
      <p>{{$t('plugIn.case-text1')}}</p>
      <el-row :gutter="140" type="flex" justify="center">
        <el-col v-for="(item,index) in introductory" :key="index" :span="5">
          <el-card :body-style="{ padding: '0px' }">
            <div class="imgIcon">
              <i class="iconfont" :class="item.icon"></i>
            </div>
            <div class="text">
              <span>{{$t('plugIn.platform')}} : {{item.platform}}</span>
              <br />
              <span>{{$t('plugIn.version')}} : {{item.versions}}</span>
              <br />
              <span>{{$t('plugIn.system')}} : {{item.equipment}}</span>
              <el-button class="btn" disabled="" @click.stop="downloadProject(item)" :id="item.id">{{$t('plugIn.project')}}</el-button>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <p class="service" v-html="$t('plugIn.case-text2')">
        
      </p>
      <h3>{{$t('plugIn.case-title')}}</h3>
      <div class="case-cantainer-back" />
    </div> -->
    <!-- 案列演示end -->
    <!-- 插件使用流程 -->
    <div class="detail-chart">
      <div>{{$t('plugIn.use')}}</div>
      <div>
        <el-button @click="$router.push('/')">
          <i class="iconfont icon-rentijiance"></i>
          <span>{{$t('plugIn.use-btn1')}}</span>
        </el-button>
        <i class="el-icon-arrow-right" />
        <!-- <el-button @click="gotoplug"> -->
        <el-button class="detail-chart-plugIn" @click="gotoplug">
          <i class="iconfont icon-chajian1"></i>
          <span>{{$t('plugIn.use-btn2')}}</span>
        </el-button>
        <i class="el-icon-arrow-right" />
        <el-button @click="getKey">
          <i class="iconfont icon-icon-safe-light"></i>
          <span>{{$t('plugIn.use-btn3')}}</span>
        </el-button>
        <i class="el-icon-arrow-right" />
        <el-button @click="$router.push('/help-center/video-teaching')">
          <i class="iconfont icon-zu56"></i>
          <span>{{$t('plugIn.use-btn4')}}</span>
        </el-button>
      </div>
      <div>
        <el-button class="detail-chart-btn" @click="$router.push('/help-center')" >{{$t('plugIn.use-btn5')}}</el-button>
      </div>
    </div>
    <!-- 插件使用流程end -->
    <!-- 获取key -->
    <div :class="$i18n.locale === 'zh' ? 'get-key': 'get-key get-key1'">
      <div class="get-key-container">
        <div class="title">CatchLightPlugin</div>
      <div class="description">
        <div class="description-select-div">
          {{$t('plugIn.platform')}} : 
          <el-select 
            @change="select1"
            ref="select1"
            v-model="value1"
            class="description-select"
            placeholder="Unity">
            <el-option
              v-for="item in unityOptions"
              :key="item.value"
              :label="$i18n.locale === 'zh' ? item.label : item.label1"
              :value="item.value"
            />
          </el-select>&nbsp;
          <el-select
          @change="select2"
            v-model="value2"
            class="description-select description-select2"
            placeholder="UE4"
            ref="select2"
          >
            <el-option
              v-for="item in ue4Options"
              :key="item.value"
              :label="$i18n.locale === 'zh' ? item.label : item.label1"
              :value="item.value"
            />
          </el-select>
        </div>
        <div>{{$t('plugIn.system')}} : {{ chackOut ? unitycompatibility:ue4compatibility}}</div>
        <div>{{$t('plugIn.update')}} : {{$i18n.locale === 'zh' ? "更新中": "updating"}}</div>
      </div>
      <div class="get-key-btn">
        <el-button class="get-key-btn-first" @click="downloadPlug" id="downloadPlugIn">{{$t('plugIn.btn1')}}</el-button>
        <el-button @click="getKey">{{$t('plugIn.btn2')}}</el-button>
        <el-button @click="downloadHaloStudio">{{$t('plugIn.btn3')}}</el-button>
      </div>
      </div>
    </div >
    <!-- 获取keyend -->
    <!-- 视频教学 -->
    <div class="teaching-video">
      <div class="teaching-video-div">{{$t('plugIn.title')}}</div>
      <div>
        <el-button class="teaching-video-btn1" @click="$router.push('/help-center/picture-teaching/ue')">
          <span class="font">{{$t('plugIn.doc')}}</span>
        </el-button>
        <el-button class="teaching-video-btn2" @click="$router.push('/help-center/video-teaching')">
          <span class="font">{{$t('plugIn.video')}}</span>
        </el-button>
      </div>
    </div>
    <!-- 视频教学end -->
    <login :dialog-login="dialogLogin" @login="login" />
    <sideBar ref="sideBar"/>
    <downloadDialog 
      :dialogFormVisible="dialogFormVisible" 
      :downloadForm = "downloadForm"
      @downloadClose = "downloadClose"
    />
  </div>
</template>
<script>
import login from '@/components/login'
import sideBar from '@/components/sideBar'
import canvasBack from "./components/canvas-back"
import downloadDialog from "@/components/downloadDialog"
import {animate} from '@/utils/animate'
import { toggleMessage } from '@/utils/common'
import { getItem } from "@/utils/storage";
// import { Vika } from '@vikadata/vika'
export default {
  name: "PlugIn",
  components: {
    canvasBack,
    downloadDialog,
    login,
    sideBar
  },
  data() {
    return {
      chackOut:true,
      dialogLogin:false,
      dialogFormVisible: false,
      downloadForm: {
        url: '',
        name: ''
      },
      unityOptions: [ 
        {
          value: '选项1',
          label: 'Unity 版本：2020.3',
          label1: 'Unity Version 2020.3'
        }
        ],
         ue4Options:[
        {
          value: '选项1',
          label: 'UE4 版本：4.26.2',
          label1: 'UE4 Version 4.26.2'
        },
      ],
      unitycompatibility: "Windows 、 Android 、IOS",//unity支持版本
      ue4compatibility:"Windows",//ue4支持版本
      // introductory:[{
      //   id: 'downloadTheProject1',
      //   icon:"icon-unity",
      //   platform:"Unity",
      //   versions:"Unity 2020.3",
      //   equipment:"Windows",
      //   url: 'https://holodata.prometh.xyz/PluginPackage/Output/UnityCatchlightPluginDemo.zip'
      // },
      // {
      //   id:"downloadTheProject2",
      //   icon:"icon-unrealengine",
      //   platform:"UE4",
      //   versions:"UE 4.26.2",
      //   equipment:"Windows", 
      //   url:'https://holodata.prometh.xyz/PluginPackage/Output/UECatchlightPluginDemo.zip'
      // }],//简介和工程下载
      value1: "",//unity选中的值
      value2:'',//ue4选中的值
    };
  },
  created() {
  },
  mounted() {
    if(this.$route.query.goto == "true"){
       animate(window,900)
     } else if(this.$route.query.goto == "false"){
      animate(window,400)
     }
     this.value1 = this.unityOptions[0].value
  },
  methods: {
    //下载弹窗
    downloadClose() {
      this.dialogFormVisible = false
    },
    //联系客服
    service() {
      this.$refs.sideBar.elsePageControl()
    },
    // 获取key
    getKey(){
      if(this.$store.state.token){
        this.$router.push('/personal-center/key-manage')
      } else{
       this.dialogLogin = true
       toggleMessage('请登录','Please login')
      }
    },
    //登录窗口
    login() {
      this.dialogLogin = false
    },
    //当前选择菜单
    select1(){
      this.chackOut = true
      this.$refs.select2.$el.classList.add("description-select2")
      this.$refs.select1.$el.classList.remove("description-select2")
      this.value2 = ''
    },
    select2(){
      this.chackOut = false
      this.value1 = ''
      this.$refs.select1.$el.classList.add("description-select2")
      this.$refs.select2.$el.classList.remove("description-select2")
    },
    //下载插件
    downloadPlug(){
      if(this.chackOut) {
   
        if (getItem('DownloadCompleted')||this.$store.state.token) {
          const a  = document.createElement('a')
          a.href = 'https://holodata.prometh.xyz/PluginPackage/publish/UnityPlugin.rar';
          a.download = this.value1
          document.body.appendChild(a)
          a.click()
          a.remove()
        } else {
          this.downloadForm = {
            url : 'https://holodata.prometh.xyz/PluginPackage/publish/UnityPlugin.rar',
            name : this.value1
          }
          this.dialogFormVisible = true
        }
      } else {
        if (getItem('DownloadCompleted')||this.$store.state.token) {
          const a  = document.createElement('a')
          a.href = 'https://holodata.prometh.xyz/PluginPackage/publish/UEPlugin.rar';
          a.download = this.value2
          document.body.appendChild(a)
          a.click()
          a.remove()

        } else {
          this.downloadForm = {
            url : 'https://holodata.prometh.xyz/PluginPackage/publish/UEPlugin.rar',
            name : this.value2
          }
          this.dialogFormVisible = true
        }
      }
    },
    downloadHaloStudio() {
      if (getItem('DownloadCompleted')||this.$store.state.token) {
        const a  = document.createElement('a')
        a.href = 'https://holodata.prometh.xyz/PluginPackage/publish/HaloStudio.rar';
        a.download = this.value1
        document.body.appendChild(a)
        a.click()
        a.remove()
      } else {
        this.downloadForm = {
          url : 'https://holodata.prometh.xyz/PluginPackage/publish/HaloStudio.rar',
          name : 'HaloStudio.rar'
        }
        this.dialogFormVisible = true
      }

    },
    //下载工程
    downloadProject(item) {
     if(this.$store.state.token) {
        const a  = document.createElement('a')
        a.href = item.url;
        a.download = item.platform
        document.body.appendChild(a)
        a.click()
        a.remove()
     } else {
       this.dialogLogin = true
       toggleMessage('请登录','Please login')
     }
    },
    gotoplug(){
      animate(window,900)
    },
    experience(){
      animate(window,400)
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  //头部
  .header {
    position: relative;
    width: 100%;
    height: 418px;
    background-color: #7c2d37;
    background: url("https://holodata.prometh.xyz/webMetaTrade/pulgbg.png") no-repeat center;
    background-size: 100% 100%;
    .mask-layer {
      position: absolute;
      top: 40%;
      left: 50%;
      z-index: 5;
      transform: translateX(-50%);
      text-align: center;
      div {
        color: #fff;
        font-size: 18px;
        line-height: 35px;
        .experience-btn1,
        .experience-btn2 {
          width: 216px;
          height: 45px;
          border-radius: 22.5px;
          font-size: 16px;
        }
        .experience-btn1 {
          margin-right: 50px;
          color: #000;
        }
        .experience-btn2 {
          color: #fff;
          background-color: transparent;
          border: 2px solid #fff;
        }
      }
      .title {
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
  //案列区域
  .case-cantainer {
    position: relative;
    color: #fff;
    width: 100%;
    text-align: center;
    overflow: hidden;
    background-image: linear-gradient(to right, #762c36, #2c0e22);
    .bgimg1 {
      position: absolute;
      right: 100px;
      bottom: 0;
    }
    .bgimg2 {
        position: absolute;
        top: 0;
        left: 0;
    }
    h3 {
      margin-top: 60px;
      margin-bottom: 30px;
    }
    .service {
      margin: 50px 0;
      .span {
        cursor: pointer;
        color: #e4ad22;
      }
    }
    .el-card {
      margin-top: 15px;
      text-align: initial;
      border: none;
      overflow: hidden;
      .imgIcon {
        width: 100%;
        height: 189px;
        text-align: center;
        line-height: 189px;
        background-color: #F2F2F2;
        .iconfont {
          font-size: 150px
        }
      }
      .text {
        margin-top: 10px;
        padding: 0 14px 14px;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        span {
          display: inline-block;
          margin-bottom: 7px;
        }
        .btn {
          margin-top: 10px;
          color: #fff;
          background-color: #a73fff;
          border: none;
          width: 100%;
        }
      }
    }
    .case-cantainer-back {
      width: 70%;
      height: 405px;
      // background: #a73fff;
      background: url("https://holodata.prometh.xyz/webMetaTrade/officialWebsite/logo-en.png") no-repeat top;
      background-size: 100% 100%;
      margin: 35px auto 50px;
    }
  }
  //详细流程图
  .detail-chart {
    height: 535px;
    background-color: #15131b;
    text-align: center;
    padding-top: 40px;
    box-sizing: border-box;
    div {
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
      .detail-chart-plugIn {
        cursor: pointer;
      }
      .detail-chart-plugIn:hover {
        background: #fff;
      }
      .el-button {
        margin-top: 80px;
        width: 192px;
        height: 192px;
        color: #5a52e7;
        font-size: 16px;
        font-weight: bold;
        .iconfont {
          display: block;
          margin-bottom: 30px;
          font-size: 70px;
        }
      }
      .detail-chart-btn {
        width: 216px;
        height: 45px;
        border-radius: 22.5px;
        font-size: 16px;
        color: #fff;
        background-color: transparent;
        border: 2px solid #fff;
      }
      .el-icon-arrow-right {
        margin: 0 10px;
        color: #5a52e7;
        font-size: 50px;
        line-height: 192px;
        vertical-align: middle;
        font-weight: 900;
      }
    }
  }
  // 获取key
  .get-key {
    position: relative;
    height: 570px;
    background: url('https://holodata.prometh.xyz/webMetaTrade/pin.png') no-repeat center;
    background-size: 100%;
    padding-top: 11.5vh;
    font-size: 18px;
      color: #fff;
    box-sizing: border-box; 
    .get-key-container {
      width: 60%;
      height: 100%;
      margin: auto;
    .bgimg3 {
      position: absolute;
      top: 30%;
      left: 53%;
      width: 50%;
      height: 60%;
    }
    .title {
      font-size: 20px;
      font-weight: bold;
    }
    .description {
      margin-top: 40px;
      margin-bottom: 60px;
      .description-select {
        .is-focus {
          .el-input__inner {
            background-color: transparent !important;
          }
        }
      }
      div {
        margin: 30px 0;
      }
    }
    .get-key-btn {
      margin-top: 50px;
      .el-button {
        height: 45px;
        padding: 0 40px;
        background-color: transparent;
        border-radius: 22.5px;
        color: #fff;
        margin-right: 30px;
        border: 2px solid #f3f3f3;
        font-size: 18px;
      }
      .get-key-btn-first {
        background-color: #ffc322;
        color: #000;
        border: 2px solid #ffc322;
      }
    }
    }

  }
  .get-key1 {
    background: url('https://holodata.prometh.xyz/webMetaTrade/officialWebsite/plug.png') no-repeat center;
    background-size: 100%;
  } 
  // 视频教学区域
  .teaching-video {
    height: 596px;
    // background-image: linear-gradient(to right, #762c36, #2c0e22);
    background-color: #15131b;
    text-align: center;
    overflow: hidden;
    .teaching-video-div {
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
      margin: 70px 0;
    }
    .teaching-video-btn1,
    .teaching-video-btn2 {
      position: relative;
      border: none;
      width: 466px;
      height: 251px;
      border-radius: 10px;
      color: #000;
      font-size: 37px;
      font-weight: bold;
      & .font {
        position: absolute;
        top: 50%;
        left: 15%;
        transform: translateY(-50%);
      }
    }
    .teaching-video-btn1 {
      margin-right: 100px;
      background: url('https://holodata.prometh.xyz/webMetaTrade/document.png') no-repeat;
      background-size: contain;
    }
    .teaching-video-btn2 {
      background: url('https://holodata.prometh.xyz/webMetaTrade/video.png') no-repeat;
      background-size: contain;
    }
  }
}
</style>
<style lang="scss">
.description {
  .description-select-div {
    height: 60px;
    .description-select {
      color: #000;

      .el-input__inner {
        color: #000;
      }
      .is-focus {
        .el-input__inner {
          border-color: #fff;
        }
        .el-input__inner:focus {
          border-color: #fff;
        }
      }
      .el-input__inner:focus {
        border-color: #fff;
      }
      .el-input__inner::-webkit-input-placeholder {
        color: #000;
      }
    }
    .description-select2 {
      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
      .el-input__inner::-webkit-input-placeholder {
        color: #fff;
      }
    }
  }
}
</style>
